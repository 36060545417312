export default {
  "default": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Innowacyjna aplikacja do spotkań"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu to rewolucyjna aplikacja mobilna umożliwiająca znajdywanie nowych znajomych. Uczestnicz w wydarzeniach i korzystaj ze zniżek!"])}
  },
  "shopButton.googleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz z Google Play"])},
  "shopButton.appleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz z AppStore"])},
  "imageAlt": {
    "phone": {
      "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Lista chatów"])},
      "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Kupon zniżkowy"])},
      "coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Lista kuponów"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Szczegóły wydarzenia"])},
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Lista wydarzeń"])},
      "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Mapa z wydarzeniami i miejscami"])},
      "meetingChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Chat z uczestnikami spotkania"])},
      "meetingMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Mapa z lokalizacjami uczetników spotkania"])},
      "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Lista użytkowników w okolicy"])},
      "placeCoupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Kupony baru / klubu"])},
      "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Szczegóły miejsca"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu - Profil użytkownika"])}
    }
  },
  "component": {
    "homeSlide": {
      "home": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tullu"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja zaprojektowana do spotykania się z przyjaciółmi w łatwy sposób oraz do poznawania nowych znajomych. Jeśli szukasz imprez w swojej okolicy lub chcesz pogadać z nowymi osobami, Tullu jest dla ciebie. Zainstaluj za darmo i baw się dobrze!"])}
      },
      "quickEvents": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotkania"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spotkania"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli czujesz się samotny i chcesz zobaczyć się z ludźmi, możesz użyć naszego Tullu spotkania. Spotkaj się z osobami za pomocą jednego przycisku! Spowoduje to wysłanie powiadomienia do wszystkich użytkowników Tullu, którzy są blisko ciebie. Zobaczą oni twoją lokalizacje na mapie. To takie proste!"])}
      },
      "people": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludzie"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ludzie"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz dodawać osoby spotkane przy barze lub imprezie korzystając z Tullu QR code scanner. Możesz też poznać innych blisko ciebie korzystając z opcji odkrywania w aplikacji.. Czatuj z nimi używając Tullu i umów się na spotkanie."])}
      },
      "events": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydarzenia"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydarzenia"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne okno Tullu pomoże ci znaleźć lokalne wydarzenia oraz imprezy, pokazując ci je na mapie. Jeśli szukasz imprez w konkretnej odległości, przejdź do strony wydarzeń i w łatwy sposób przejrzyj wszystkie potencjalne miejsca oraz najważniejsze informacje. Tullu pozwoli ci na stworzenie własnego wydarzenia. Wybierz kto i ile osób może dołączyć. Możesz zorganizować najlepszą imprezę dla wszystkich na świecie lub przygotować ją tylko dla ludzi z twojej listy znajomych. Jeśli lubisz posiadówki w elitarnym gronie, możesz wysłać zaproszenie tylko dla wybranych."])}
      },
      "partners": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerzy"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerzy"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponieważ Tullu jest skoncentrowane na współpracy z innymi partnerami biznesowymi, dzięki temu dostaniesz dostęp do specjalnych wydarzeń oraz nagród. Dodatkowo będziesz zaproszony na ekskluzywne imprezy Tullu, przeznaczone tylko dla naszych użytkowników."])}
      },
      "coupons": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupony"])},
        "navTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupony"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli kiedykolwiek byłeś zdziwiony, że nikt nie rozdaje zniżek na drinki lub bilety do twoich ulubionych miejscówek, mamy na to rozwiązanie. Tullu pozwala naszym partnerom na ich tworzenie. Będą one pokazywane na stronie kuponów w aplikacji. Bądź szybki, ponieważ będą one dostępne tylko przez krótki okres czasu."])}
      }
    },
    "shopButton": {
      "commingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Już wkrótce"])}
    },
    "scrollDown": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej"])}
    },
    "goToTop": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wróć na początek"])}
    }
  }
}